<template>
  <div class="faq">
    <div class="pc">
      <!-- 질의 -->
      <div class="faq-question unselect subtitle4 main" @click="toggle()">
        <div class="flex"><div class="q-st">Q</div>{{ faq.question }}</div>
        <img src="/static/icon/u_angle-up.svg" class="svg-primary" v-if="!folded">
        <img src="/static/icon/u_angle-down.svg" style="margin-left:32px" v-if="folded">
      </div>
      <!-- 답변 -->
      <div class="faq-answer" v-if="!folded">
        <div class="subtitle4 flex">
          <div class="a-st">A</div>
          <div class="body2 sub" v-html="faq.answer"></div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <!-- 질의 -->
      <div class="faq-question unselect subtitle4 main" @click="toggle()">
        <div class="flex">
          <div class="q-st subtitle5">Q</div>
          <div class="subtitle7">{{ faq.question }}</div>
        </div>
        <img src="/static/icon/u_angle-up.svg" class="svg-primary" v-if="!folded">
        <img src="/static/icon/u_angle-down.svg" style="margin-left:32px" v-if="folded">
      </div>
      <!-- 답변 -->
      <div class="faq-answer" v-if="!folded">
        <div class="subtitle4 flex">
          <div class="a-st subtitle5">A</div>
          <div class="body4 sub" v-html="faq.answer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "faq-item",
    props: ['faq'],
    methods: {
      toggle() {
        this.folded =  !this.folded;
      }
    },
    data() {
      return {
        folded: true
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .faq-question
    display flex
    justify-content space-between
    align-items center
    font-size 18px
    border-bottom 1px solid $gray2

  .faq-answer
    font-size 14px
    white-space pre-wrap
    background-color $gray4
    border-bottom 1px solid $gray2

  .folded-question
    color #303030

  .arrow-down
    color #999999

  .q-st
  .a-st
    margin-right 12px

  .q-st
    color $primary
  .a-st
    color $error

  .selected
    color $primary

  .pc
    .faq-question
      padding 16px
    .faq-answer
      padding 24px

  .mobile
    .faq-question
      padding 12px
    .faq-answer
      padding 16px
</style>
